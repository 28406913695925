import React, { useEffect, useRef, useState } from "react";
import LoadingIconGray from "../../components/Common/LoadingGray";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowRight, Filter, UpDownArrow } from "../../assets/svg/SVG";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import { addDays, format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { toast } from "react-toastify";
import { CustomPagination } from "../../helper/CustomPagination";
import { formatDateTimeWithTimezone } from "../../helper/utils";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [journalType, setJournalType] = useState();
  const [messageType, setMessageType] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [logSortedOrder, setLogSortedOrder] = useState("asc");
  const [timeSortedOrder, setTimeSortedOrder] = useState("asc");
  const menuRef = useRef();
  const [selectedText, setSelectedText] = useState();
  const [iscTrader, setIsCtrader] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const showContextMenu = (event) => {
    event.preventDefault();
    setMenuPosition({ top: event.clientY, left: event.clientX });
    setMenuVisible(!menuVisible);
  };

  const hideContextMenu = () => {
    setMenuVisible(false);
  };

  document.addEventListener("mouseup", function () {
    var selectedText = window.getSelection().toString().trim();

    //selectedText = selectedText.replace(/(^|\n)(?!\d)/g, "");

    if (selectedText !== "") {
      setSelectedText(selectedText);
    } else {
      console.log("No text selected to copy.");
    }
  });

  async function saveToFile(content, fileName, mimeType) {
    try {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: fileName,
        types: [
          {
            description: "CSV File (*.csv)",
            accept: {
              "text/csv": [".csv"],
            },
          },
          {
            description: "Log File (*.log)",
            accept: {
              "text/plain": [".log"],
            },
          },
          {
            description: "HTML File (*.html)",
            accept: {
              "text/html": [".html", ".htm"],
            },
          },
          {
            description: "Text File (*.txt)",
            accept: {
              "text/plain": [".txt"],
            },
          },
        ],
      });

      const writable = await fileHandle.createWritable();
      await writable.write(content);
      await writable.close();
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Save operation aborted by user.");
        // Handle user aborting save operation
      } else {
        console.error("Error saving file:", error);
        // Handle other errors
      }
    }
  }

  function convertJSONtoCSV(jsonData) {
    const lines = jsonData.split("\n");

    // Convert lines into CSV format
    let csvContent = "Timestamp,IP,Action\n";
    let splitText = selectedText.split("\n");
    let firstIdx = splitText[0];
    let lastIdx = splitText[splitText.length - 3];
    console.log(firstIdx, lastIdx);

    logs.forEach((entry) => {
      // if (entry.id >= firstIdx && entry.id <= lastIdx) {
      csvContent += `"${entry.time}","${entry.source}","${entry.text}"\n`;
      //}
    });

    return csvContent;
  }

  const exportData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const csvContent = convertJSONtoCSV(selectedText);
    saveToFile(csvContent, "logs", "text/csv");
    hideContextMenu();
  };

  const copyLogins = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const pattern = /'\d+':/g;
    const matches = selectedText.match(pattern);
    let result = matches
      ?.map((match) => match.replaceAll("'", "").replaceAll(":", "") + "\n")
      .join("");
    navigator.clipboard.writeText(result);
    hideContextMenu();
  };

  const copyLines = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const lines = selectedText.split("\n");
    let result = "";

    for (let i = 0; i < lines.length; i += 3) {
      if (i + 3 <= lines.length) {
        result += lines.slice(i, i + 3).join(" ") + "\n";
      } else {
        result += lines.slice(i).join(" ");
      }
    }

    navigator.clipboard.writeText(result);
    hideContextMenu();
  };

  const copyTickets = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const pattern = /#\d+/g;
    const matches = selectedText.match(pattern);
    let result = matches?.map((match) => match + "\n").join("");
    navigator.clipboard.writeText(result);
    hideContextMenu();
  };

  const copyIpAddress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ipPattern = /\b(?:\d{1,3}\.){3}\d{1,3}\b/g;
    const matches = selectedText.match(ipPattern);
    let result = matches?.map((match) => match + "\n").join("");
    navigator.clipboard.writeText(result);
    hideContextMenu();
  };

  const copyAll = () => {
    const dataLines = document.querySelectorAll(".data-line");
    let allData = "";

    dataLines.forEach((line, index) => {
      const time = line.querySelector(".time").textContent;
      const source = line.querySelector(".source").textContent;
      const text = line.querySelector(".text").textContent;

      allData += `${
        index + 1
      }. Time: ${time}, Source: ${source}, Text: ${text}\n`;
    });

    navigator.clipboard
      .writeText(allData)
      .then(() => alert("All data copied to clipboard"))
      .catch((error) => console.error("Error copying data: ", error));
  };

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // Get the current date
  let currentDate = new Date();

  // Get the previous day
  let previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);

  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    let day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  let today = formatDate(currentDate);
  let previousDay = formatDate(previousDate);

  const handleDateRange = (item) => {
    setDateState([item.selection]);

    const startDateFormatted = format(item.selection.startDate, "yyyy-MM-dd");
    const endDateFormatted = format(item.selection.endDate, "yyyy-MM-dd");

    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
  };

  const handleShowDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReset = () => {
    setSearchValue("");
    setJournalType({ value: "0", label: "FULL" });
    setMessageType({ value: "0", label: "ALL" });
  };

  const handleSearch = async (token, email) => {
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
        Authorization: `${token}`,
      },
      method: "POST",
      body: JSON.stringify({
        login: email,
        filter: searchValue,
        start: startDate ? startDate : previousDay,
        end: endDate ? endDate : today,
        journal_type: journalType ? parseInt(journalType.value) : 0,
        message_type: messageType ? parseInt(messageType.value) : 0,
      }),
    };

    let server = localStorage.getItem("journalServer");
    let baseUrl;
    if (server == "gn") {
      baseUrl = process.env.REACT_APP_GN_BASE_URL;
    } else if (server == "cdemo") {
      baseUrl = process.env.REACT_APP_CTRADER_DEMO_BASE_URL;
      setIsCtrader(true);
    } else if (server == "clive") {
      baseUrl = process.env.REACT_APP_CTRADER_LIVE_BASE_URL;
      setIsCtrader(true);
    } else {
      baseUrl = process.env.REACT_APP_FN_BASE_URL;
    }

    try {
      let res = await fetch(
        `${baseUrl}/logs?page=${currentPage}&rows=1000`,
        requestOptions
      );
      const data = await res.json();
      if (res.status != 200) {
        toast(data?.message);
        if (res.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          navigate("/auth/login");
        }
      } else {
        setLogs(data.data);
        setCurrentPage(data.current_page);
        setTotalPage(data.last_page);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const sortedLogs = () => {
    setLogSortedOrder(logSortedOrder === "asc" ? "desc" : "asc");

    const sortedLogsArray = [...logs].sort((a, b) => {
      const comparison = a.source.localeCompare(b.source);
      return logSortedOrder === "asc" ? comparison : -comparison;
    });

    setLogs(sortedLogsArray);
  };

  const sortedTime = () => {
    setTimeSortedOrder(timeSortedOrder === "asc" ? "desc" : "asc");

    const sortedTimeArray = [...logs].sort((a, b) => {
      const comparison = a.time.localeCompare(b.time);
      return timeSortedOrder === "asc" ? comparison : -comparison;
    });

    setLogs(sortedTimeArray);
  };


  useEffect(() => {
    let token = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    if (token && email) {
      setToken(token);
      setEmail(email);
      handleSearch(token, email);
    }
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div>
        {menuVisible && (
          <div
            style={{
              position: "absolute",
              top: menuPosition.top,
              left: menuPosition.left,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              height: "auto",
              width: "200px",
              zIndex: 1000,
              borderRadius: "5px",
            }}
          >
            <ul className="context-menu-list">
              <li className="menu-item">
                Copy As
                <ul className="context-submenu">
                  <li className="menu-item" onClick={(e) => copyLines(e)}>
                    Lines
                  </li>
                  <li className="menu-item" onClick={(e) => copyLogins(e)}>
                    List Of logins
                  </li>
                  <li className="menu-item" onClick={(e) => copyTickets(e)}>
                    list Of tickets
                  </li>
                  <li className="menu-item" onClick={(e) => copyIpAddress(e)}>
                    list Of IP address
                  </li>
                </ul>
              </li>
              <li className="menu-item" onClick={(e) => exportData(e)}>
                Exports
              </li>
            </ul>
          </div>
        )}
      </div>
      <div onContextMenu={showContextMenu} className="w-full" ref={menuRef}>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="flex justify-between mb-4">
            <h3 className="text-2xl font-bold text-[#000000] flex items-center gap-x-5">
              <img src="/journal.png" alt="" /> <span>Journal Information</span>
            </h3>
            <div className="flex">
              <div className="relative">
                <img
                  className="absolute top-[14px] left-[10px]"
                  src="/Icon.png"
                  alt="search"
                />
                <input
                  value={searchValue}
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(token, email);
                    }
                  }}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="px-8 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                  placeholder="Search for Journal"
                />
              </div>
              <button
                onClick={() => setIsFilter(!isFilter)}
                className="border-[#2A7FFE] ml-4 border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
              >
                <Filter />
                <span className="mx-[10px] text-lg">Filter</span>
                <span className={`${isFilter ? "" : "rotate-180"}`}>
                  <ArrowRight />
                </span>
              </button>
            </div>
          </div>

          {isFilter && (
            <div className=" bg-[#F8F9FF] px-8 py-8 rounded-md  mb-10">
              <div className="flex items-center flex-wrap gap-y-4 2xl:gap-y-6">
                <div className="w-full md:w-1/2 lg:w-4/12  px-4">
                  <CustomDropDown
                    value={journalType}
                    onChange={(value) => setJournalType(value)}
                    options={
                      iscTrader
                        ? [
                            { value: "1", label: "Login Journal" },
                            { value: "2", label: "Trading Journal" },
                          ]
                        : [
                            { value: "0", label: "Standard Logs" },
                            { value: "1", label: "Errors only" },
                            { value: "2", label: "FULL" },
                          ]
                    }
                    placeholder="Journal Type"
                    className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff]"
                  />
                </div>

                <div className="w-full md:w-1/2 lg:w-4/12  px-4">
                  <CustomDropDown
                    disabled={iscTrader}
                    value={messageType}
                    onChange={(value) => setMessageType(value)}
                    options={[
                      { value: "0", label: "All" },
                      { value: "1", label: "Configuration" },
                      { value: "2", label: "System" },
                      { value: "3", label: "Network" },
                      { value: "4", label: "History" },
                      { value: "5", label: "Accounts" },
                      { value: "6", label: "Trades" },
                      { value: "7", label: "API" },
                      { value: "16", label: "LIveUpdate" },
                      { value: "17", label: "Report Mailter" },
                    ]}
                    placeholder="Message Type"
                    className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff]"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 px-4">
                  <button
                    onClick={handleShowDatePicker}
                    className="border-[#2A7FFE] w-full  ml-4 border-[1px] flex items-center text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[10px] px-[17px]"
                  >
                    Show Date Range Picker
                  </button>

                  {showDatePicker && (
                    <div className="absolute top-[150px] left-[50px] 2xl:left-[420px] z-50">
                      <DateRangePicker
                        onChange={(item) => handleDateRange(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dateState}
                        direction="horizontal"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                      />
                    </div>
                  )}
                </div>

                <div className="w-full mx-4 mt-5 flex gap-x-8">
                  <button
                    onClick={() => handleSearch(token, email)}
                    className="bg-[#2A7FFE] text-white text-sm px-7 py-2 rounded font-normal hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <SearchIcon />
                    <span className="ml-2 text-lg">Search Result</span>
                  </button>
                  <button
                    onClick={() => handleReset()}
                    className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <RestartAltIcon />
                    <span className="ml-2 text-lg">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <>
              <div className="w-full overflow-hidden pb-24 mt-2">
                <div className="w-full shadow-[0px_4px_45px_#F3F3F3] text-left border-collapse">
                  <div className="mx-8 py-2 flex border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                    {/* <div className="w-1/12">ID</div> */}
                    <div className="w-3/12 flex justify-start items-center">
                      <p>Time</p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => sortedTime()}
                      >
                        <UpDownArrow />
                      </div>
                    </div>
                    <div className="w-3/12 flex justify-start items-center">
                      <p>Source</p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => sortedLogs()}
                      >
                        <UpDownArrow />
                      </div>
                    </div>
                    <div className="w-6/12 flex justify-start items-center">
                      Message
                    </div>
                  </div>
                  <div>
                    {logs?.map((log, idx) => {
                      return (
                        <div
                          key={idx}
                          className="data-line border-b mx-8 flex py-2 border-[#EDEFF3]  text-sm  hover:bg-gray-200"
                        >
                          {/* <div className="w-1/12">{idx + 1}</div> */}
                          <div className="w-3/12 flex justify-start items-center time">
                            {/* {formatDateTimeWithTimezone(log.time, "Etc/GMT+3")} */}
                            {log.time}
                          </div>
                          <div className="w-3/12 flex justify-start items-center source">
                            {log.source}
                          </div>
                          <div className="w-6/12 flex justify-start items-center text">
                            {log.text}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center">
                <CustomPagination
                  setCurrentPage={setCurrentPage}
                  current={currentPage}
                  total={totalPage}
                />
              </div>
            </>
          ) : (
            <LoadingIconGray />
          )}
        </div>
      </div>
    </div>
  );
}
